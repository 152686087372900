<template>
  <page-header-wrapper>
    <a-card>
      <div class="flex align-center">
        <div class="abox" :class="{ 'active': activeIndex == 0 }" @click="changeTab(0)">
          <img src="@/assets/images/pay/wxPay.png" alt="" class="box80">
          <div class="margin-top-sm">微信支付</div>
        </div>
        <div class="abox" :class="{ 'active': activeIndex == 1 }" @click="changeTab(1)">
          <img src="@/assets/images/pay/aliPay.png" alt="" class="box80">
          <div class="margin-top-sm">支付宝支付</div>
        </div>
        <div class="abox" :class="{ 'active': activeIndex == 2 }" @click="changeTab(2)">
          <img src="@/assets/images/pay/tonglian.png" alt="" class="box80">
          <div class="margin-top-sm">通联支付</div>
        </div>
      </div>
      <div class="margin-top">
        <div v-show="activeIndex == 0">
          <div class="text-bold margin-tb">微信支付</div>
          <a-row class="margin-bottom">
            <a-col :span="4"></a-col>
            <a-col :span="14">
              <a-radio-group v-model="atype" @change="onChangeType($event, 'wx')">
                <a-radio :value="1">
                  普通
                </a-radio>
                <a-radio :value="2">
                  服务商
                </a-radio>
              </a-radio-group>
              <a @click="isEditA = !isEditA" href="javascript:void(0)" class="text-green margin-right">
                <a-icon type="edit" />{{ isEditA ? '编辑中' : '点击编辑' }}
              </a>
            </a-col>
          </a-row>
          <wx-pay :isEdit="isEditA" :type="atype"></wx-pay>
        </div>
        <div v-show="activeIndex == 1">
          <div class="text-bold margin-tb">支付宝支付</div>
          <a-row class="margin-bottom">
            <a-col :span="4"></a-col>
            <a-col :span="14">
              <a-radio-group v-model="btype" @change="onChangeType($event, 'ali')">
                <a-radio :value="1">
                  普通
                </a-radio>
                <a-radio :value="2">
                  服务商
                </a-radio>
              </a-radio-group>
              <a @click="isEditB = !isEditB" href="javascript:void(0)" class="text-green margin-right">
                <a-icon type="edit" />{{ isEditB ? '编辑中' : '点击编辑' }}
              </a>
            </a-col>
          </a-row>
          <ali-pay :isEdit="isEditB" :type="btype" ></ali-pay>
        </div>
        <div v-show="activeIndex == 2">
          <div class="text-bold margin-tb">通联支付</div>
        </div>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
// 微信appid：wx73a9ecab70a9ff01
// 微信appsecret：fc7bce99b32eb942cc901eeeaf6b0c1e

// 微信商户mchid：1601604850
// apiv3秘钥：7788a64f2900a69a9bfc2ded39fb12a0
import * as paychannel from '@/api/pay/pay.js'
import wxPay from '../components/wxPay.vue'
import aliPay from '../components/aliPay.vue'
export default {
  components: {
    wxPay,
    aliPay
  },
  name: "pay",
  data() {
    return {
      activeIndex: 0, // 选中的支付方式
      atype: 1,       // 普通、服务商
      btype: 1,       // 普通、服务商
      isEditA: false,  // 是否是编辑状态
      isEditB: false,  // 是否是编辑状态

    }
  },
  methods: {
    /**切换支付方式 微信支付、支付宝支付、通联支付 */
    changeTab(index) {
      if(index === this.activeIndex) return
      this.activeIndex = index
      /**点击切换支付类型：重置 普通和服务商、编辑状态 为原始状态，避免使用上个支付方式的状态 */
      if(index === 0) {
        this.aType = 1
        this.isEditA = false
      }else if(index === 1) {
        this.bType = 1
        this.isEditB = false
      }
      this.$forceUpdate()
    },
    /**切换 普通和服务商 */
    onChangeType(e, type) {
      let val = e.target.value
      if(type === 'wx') {
        this.atype = val
      }else if(type === 'ali') {
        this.btype = val
      }
    }
  }
}
</script>

<style scoped lang="less">
.abox {
  width: 120px;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
}

.abox.active {
  border: 1px solid rgb(54, 187, 54);
  border-radius: 5px;
}

.box80 {
  width: 50px;
  height: 50px;
}
</style>